import { Injectable } from '@angular/core';
import { WalletAccountsModel } from './../model/polkadot.model';
import { web3Accounts, web3Enable, web3FromAddress, web3FromSource } from '@polkadot/extension-dapp';
import { cryptoWaitReady, decodeAddress, signatureVerify } from '@polkadot/util-crypto';
import { hexToU8a, isHex, stringToHex, stringToU8a, u8aToHex } from '@polkadot/util';
import { Keyring, encodeAddress } from '@polkadot/keyring';
import { ApiPromise } from '@polkadot/api';
import { WsProvider } from '@polkadot/rpc-provider';
// import { ContractPromise } from '@polkadot/api-contract';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppSettings } from 'src/app/app-settings';
import { CookiesService } from './../services/cookies.service';
import { formatBalance, BN } from '@polkadot/util';
import { ContractPromise } from '@polkadot/api-contract';
import { NFTModel } from './../model/nft.model';
import { BehaviorSubject } from 'rxjs';
import { NetworkScannerModel } from '../model/networkscanner.model';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + localStorage.getItem('token'),
  }),
};

@Injectable({
  providedIn: 'root'
})
export class PolkadotService {
  public blockSubscription: any;
  private dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private currentToken: BehaviorSubject<string> = new BehaviorSubject<any>('XON');
  private networkScanner: BehaviorSubject<NetworkScannerModel> = new BehaviorSubject<any>(NetworkScannerModel);
  setCurrentBalance(data: any) {
    this.dataSubject.next(data);
  }

  getCurrentBalance() {
    return this.dataSubject.asObservable();
  }

  constructor(
    private appSettings: AppSettings,
    private httpClient: HttpClient,
    private cookiesService: CookiesService,
  ) {
    this.getChainTokens();
  }
  public defaultAPIURLHost: string = this.appSettings.APIURLHostNFT;
  wsProvider = new WsProvider(this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint  :environment.network[0].networks[0].wsProviderEndpoint);
  api = ApiPromise.create({ provider: this.wsProvider });
  keypair = this.appSettings.keypair;
  extensions = web3Enable('XGAME DASHBOARD');
  accounts = web3Accounts();
  abi = require("./../../../assets/json/sample.json");

  getAbi(): Observable<any> {
    return this.httpClient.get<any>(this.abi);
  }

  async connect() {
    const provider = new WsProvider(this.appSettings.wsProviderEndpoint);
    const api = await ApiPromise.create({ provider });
    return api;
  }


  public async setTotalBlocks(): Promise<void> {
    let network: NetworkScannerModel = new NetworkScannerModel();
    // let new_api = this.initialize_provider();
    const api = await this.api;

    // Get Total Wallet
    const totalWallet: any = await api.query.system.account.entries();
    network.total_wallets = totalWallet.length

    // Get Net Name
    network.net_name = this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').net_name  : environment.network[0].networks[0].net_name

    // Get Token Name
    const tokens = await api.registry.chainTokens;
    network.token_name = tokens[0]
    this.currentToken.next(tokens[0])

    this.blockSubscription = api.derive.chain.subscribeNewHeads(async (lastHeader: any) => {
      const events: any = await api.query.system.events.at(lastHeader.hash);
      events.forEach(async ({ event }) => {
        // if (event.method == 'TransactionFeePaid') {
          const properties = await api.rpc.system.properties();
          const data: any = properties.toHuman();
          const symbol = data.tokenSymbol[0];
          const decimal = data.tokenDecimals[0];
          formatBalance.setDefaults({ decimals: decimal, unit: symbol });
          formatBalance.getDefaults();
          const bal = formatBalance(
            event.data.actualFee,
            {
              forceUnit: symbol,
              withUnit: false
            }
          );
          const balances = parseFloat(bal).toFixed(6);
          const fee_format = `${balances}`;
          network.last_gas_fee = parseFloat(fee_format) == 0.000000 ? 0.00212 : parseFloat(fee_format);
        // }
      });
      // const latest_fee = localStorage.getItem("latest_fee");
      // network.last_gas_fee = latest_fee == undefined ? '0.00212' : latest_fee;
      network.total_blocks = lastHeader.number.toNumber();
      this.networkScanner.next(network)
    });
  }


  async getAllSmartContracts() {
    let api = await this.api;
    const allContracts = await api.query.contracts.contractInfoOf.entries();

    // Extract contract addresses from the result
    const contractAddresses = allContracts.map(([key, _]) => key.args[0].toJSON());
    this.cookiesService.setCookie('smart_contract',contractAddresses[0]);
  }

  async getContract(api: any, abi: any, contractAddress: any) {
    try {
      const contract = new ContractPromise(api, abi, contractAddress);
      return contract;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async getAccount(contractAddress: any) {
    try {
      let accounts = [];

      do {
        await web3Enable('XGAME DASHBOARD');
        accounts = await web3Accounts();
        if (accounts.length === 0) {
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } while (accounts.length === 0);
      // const SENDER = this.appSettings.wallet_info.wallet_keypair;
      // console.log(this.cookiesService.getCookieArray("wallet-info").address);
      const SENDER = this.cookiesService.getCookieArray("wallet-info").address;
      const injector = await web3FromAddress(SENDER);
      let api = await this.api;
      const contract = await this.getContract(api, this.abi, contractAddress);

      // Returns the data
      return { api, SENDER, injector, contract };
    } catch (error) {
      console.error('Get account error: ' + error);
      return undefined;
    }
  }

  async getBalance() {
    try {
      const contractAddress = await this.getAllSmartContracts();
      const accountData = await this.getAccount(contractAddress);

      if (accountData && accountData.api) {
        const { api, SENDER, contract } = accountData;
        const accountInfo: any = await api.query.system.account(SENDER);
        const { nonce, data: balance } = accountInfo.toJSON();
        const chainDecimals = api.registry.chainDecimals[0];
        formatBalance.setDefaults({ decimals: chainDecimals, unit: 'NMS' });
        formatBalance.getDefaults();
        const free = formatBalance(balance.free, { forceUnit: "NMS", withUnit: false });
        const balances = free.split(',').join('');
        return balances;
      } else {
        console.error('API not available in the returned data.');
        return undefined;
      }
    } catch (error) {
      console.error('Get account balance error:', error);
      return undefined;
    }
  }




  async getWeb3Accounts(): Promise<WalletAccountsModel[]> {
    let walletAccounts: WalletAccountsModel[] = [];

    if ((await this.extensions).length > 0) {
      const accounts = await this.accounts;
      if (accounts.length > 0) {
        for (let i = 0; i < accounts.length; i++) {
          walletAccounts.push({
            address: accounts[i].address,
            address_display:  accounts[i].address.substring(0, 5) + "..." + accounts[i].address.substring(accounts[i].address.length - 5, accounts[i].address.length),
            metaGenesisHash: accounts[i].meta.genesisHash,
            metaName: accounts[i].meta.name,
            tokenSymbol: "",
            metaSource: accounts[i].meta.source,
            type: accounts[i].type
          });
        }
      }
    }

    return walletAccounts;
  }
  async signAndVerify(walletAccount: WalletAccountsModel): Promise<boolean> {
    const injector = await web3FromSource(String(walletAccount.metaSource));
    const signRaw = injector?.signer?.signRaw;

    if (!!signRaw) {
      await cryptoWaitReady();

      const message: string = 'Please sign before you proceed. Thank you!';
      const { signature } = await signRaw({
        address: walletAccount.address,
        data: stringToHex(message),
        type: 'bytes'
      });

      let publicKey = decodeAddress(walletAccount.address);
      let hexPublicKey = u8aToHex(publicKey);

      let { isValid } = signatureVerify(message, signature, hexPublicKey);
      return isValid;
    }

    return false;
  }
  async generateKeypair(address: string): Promise<string> {
    const keyring = new Keyring({ type: 'sr25519', ss58Format: 0 });
    const hexPair = keyring.addFromAddress(address);

    return hexPair.address;
  }

  async get_all_nfts(): Promise<NFTModel[]> {

    return new Promise<NFTModel[]>(async (resolve, reject) => {

      const contractCookie = this.cookiesService.getCookie('smart_contract');

      this.api.then(async (api) => {

        const contract = await this.getContract(api, this.abi, contractCookie);

        const gasLimit = api.registry.createType(
          'WeightV2',
          api.consts.system.blockWeights['maxBlock']
        );

        if (!contract) {
          reject(new Error('Contract not initialized.'));
          return;
        }

        if (!contract.query || !contract.query['getAllTokens']) {
          reject(new Error('getAllTokens function not found in the contract ABI.'));
          return;
        }

        if (contractCookie !== null) {

          // let nfts = contract.query['getAllTokens'];
          // console.log(nfts)
          contract.query['getAllTokens'](contractCookie, { gasLimit: gasLimit })
            .then(({ output }) => {
              const toks: any = output?.toJSON();

              if (toks.ok.length !== 0) {
                const nftModel: NFTModel[] = [];

                for (const tokenData of toks.ok) {
                  const token: NFTModel = {
                    nftTokenId: tokenData.nftTokenId,
                    imagePath: tokenData.imagePath,
                    name: tokenData.name,
                    description: tokenData.description,
                    price: tokenData.price,
                    isForSale: tokenData.isForSale,
                    isEquipped: tokenData.isEquipped,
                    category: tokenData.category,
                    collection: tokenData.collection,
                    astroType: tokenData.astroType,
                    rarity: tokenData.rarity,
                    network: tokenData.network,
                    blockchainId: tokenData.blockchainId,
                    collectionId: tokenData.collectionId,
                    tokenOwner: tokenData.tokenOwner,
                  };
                  nftModel.push(token);
                }

                resolve(nftModel);
              } else {
                resolve([]);
              }
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(new Error('Contract cookie not found'));
        }
      });
    });
  }

  isAddressValid(walletAddress: string) {
    try {
      encodeAddress(
        isHex(walletAddress)
          ? hexToU8a(walletAddress)
          : decodeAddress(walletAddress)
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getChainDecimals(amount: number) {
    let api = await this.api;
    const factor = new BN(10).pow(new BN(api.registry.chainDecimals));
    const convertedAmount = new BN(amount).mul(factor);
    return convertedAmount;
  }

  async checkBalance(wallet_address) {
    let api = await this.api;
    const balance = await api.derive.balances.all(wallet_address);
    const available = balance.availableBalance;
    const chainDecimals = api.registry.chainDecimals[0];
    formatBalance.setDefaults({ decimals: chainDecimals, unit: 'NMS' });
    formatBalance.getDefaults();
    const free = formatBalance(available, { forceUnit: "NMS", withUnit: false });
    const balances = free.split(',').join('');
    return parseFloat(balances) < 100 ? true : false;
  }

  async getChainTokens(): Promise<string> {
    const api = await this.api;
    const tokens = api.registry.chainTokens;
    this.cookiesService.setCookie('tokenSymbol', tokens[0]);
    return tokens[0];
  }

  async getAstroToken(): Promise<any> {
    let wallet = this.cookiesService.getCookieArray("wallet-info");
    const api = await this.api;
    try {
      const price = 1;
      const accountInfo: any = await api.query.assets.account(
        1,
        wallet.address
      );
      const metadata: any = await api.query.assets.metadata(
        1,
      );
      if (accountInfo.toHuman() != null) {
        const { balance } = accountInfo.toJSON();
        const { decimals, symbol } = metadata.toHuman();
        formatBalance.setDefaults({ decimals: parseInt(decimals), unit: symbol });
        formatBalance.getDefaults();
        const bal = formatBalance(
          balance,
          {
            forceUnit: symbol,
            withUnit: false
          }
        );
        const balances = parseFloat(bal).toFixed(4);
        return {
          balance: balances,
          price: price,
          symbol: symbol,
          success: true
        };
      } else {
        return {
          balance: '0.0000',
          price: price,
          symbol: 'ASTRO',
          success: false
        };
      };
    } catch (error) {
      throw String(error || 'balanceOfRepo error occurred.');
    }
  }

  public async transferNativeToken(
    wallet_address: string,
    amount: number
  ): Promise<string> {
    const api = await this.api;
    const sender = this.cookiesService.getCookieArray("wallet-info").address;
    const injector = await web3FromAddress(sender);
    const chainDecimals = api.registry.chainDecimals[0];
    const value = amount * 10 ** chainDecimals;
    const tx = await api.tx.balances.transfer(
      wallet_address,
      value
    ).signAsync(
      sender,
      { signer: injector.signer }
    );
    const txString = JSON.stringify(tx);
    const txn = JSON.parse(txString);
    return txn;
    // await this.submitTx(txString);
  }

  public async submitTx(tx: string): Promise<any> {
    return new Observable<[boolean, any]>((observer) => {
      this.httpClient.post<any>(
        this.defaultAPIURLHost + '/nfts/signed',
        { sign: tx },
        httpOptions
      ).subscribe({
        next: (response) => {
          let results = response;
          if (results != null) {
          }
          observer.next([true, results]);
          observer.complete();
        },
        error: (error) => {
          observer.next([false, error.status]);
          observer.complete();
        }
      });
    });
  }
}
