<div class="media profile-media">
  <!-- <img class="b-r-10" src="assets/images/dashboard/profile.png" alt=""> -->
  <div style="padding: 4px 6px; display: flex;" style="margin-left: 5px; margin-top: 3px; color: #fdf7f7;">
    <i class="icofont icofont-wallet icon-size-wallet"></i>
  </div>
  <div class="media-body" style="color: #fdf7f7;">
    <span>{{wallet_info.metaName}}</span>
    <p class="mb-0 font-roboto" style="margin-left: 5px; color: #fdf7f7;">{{wallet_info.address_display}} <i class="middle fa fa-angle-down"></i></p>
  </div>
</div>

<ul class="profile-dropdown onhover-show-div ">
  <li style="display: flex; justify-content: space-between;">
    <span>{{appSettings.translate("Balance")}}:</span>
    <span> {{wallet_balance}} {{ tokenSymbol }}</span>
  </li>
  <li style="display: flex; justify-content: space-between;">
    <span>{{appSettings.translate("Wallet")}}:</span>
    <span style="display: flex; gap: 0.5rem;"> {{wallet_info.address_display}} <a href="javascript:void(0);" (click)="copyInputMessage(wallet_info.address_display)"><i class="fa fa-copy"></i></a></span>
  </li>
  <li><a routerLink="/"><app-feather-icons [icon]="'settings'"></app-feather-icons><span>{{appSettings.translate("Dashboard")}}</span></a></li>
  <li><a href="javascript:void(0);" (click)="logout()"><app-feather-icons [icon]="'log-in'"></app-feather-icons><span>{{appSettings.translate("Log Out")}}</span></a></li>
</ul>
