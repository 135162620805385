<!-- Page Header Start-->
<div class="page-header" [class.close_icon]="navServices.collapseSidebar">
  <!-- <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 section-justify-center" style="background-color: #0FFFFF;">
    <div class="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
          <app-swiper></app-swiper>
        </div>
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 section-flex-end section-align-center show-social-media" style="padding-left: 0px; padding-right:0px;">
          <div style="display: flex;flex-wrap: wrap;">
            <a [href]="social_media.path" target="_blank" *ngFor="let social_media of social_media_menuItems">
              <button class="btn-light" placement="below" ngbTooltip="{{appSettings.translate(social_media.title)}}" style="display:flex; align-content: center;
          flex-wrap: wrap;color: black !important;background-color: unset !important;border: unset;">
                <i class="icofont icofont-{{social_media.icon}} f-15" *ngIf="social_media.title!='Discord'"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16"  *ngIf="social_media.title=='Discord'" >
                  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div class="header-wrapper row m-0 section-justify-center">
    <div class="col-xxl-9 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row ">
        <!-- <app-search></app-search> -->
        <div class="header-logo-wrapper col-auto p-0">
          <div class="logo-wrapper">
            <a [routerLink]="['/portfolio']">
              <img class="img-fluid for-light" src="assets/images/home/humidefi-logo/landing-page/humidefi-logo_100px.png" alt="" />
              <img class="img-fluid for-dark" src="assets/images/home/humidefi-logo/landing-page/humidefi-logo_100px.png" alt="" />
            </a>
          </div>
          <div class="toggle-sidebar" (click)="sidebarToggle()">
            <app-feather-icons [icon]="'align-center'" class="status_toggle middle"></app-feather-icons>
          </div>
        </div>
        <div class="nav-right col-xxl-7 col-xl-6 col-md-7 col-8 pull-right right-header p-0 ms-auto" style="width: unset !important;">
          <ul class="nav-menus">
            <!-- <li>
              <span class="header-search" (click)="searchToggle()">
                <svg>
                  <use href="assets/svg/icon-sprite.svg#search"></use>
                </svg>
              </span>
            </li> -->
            <!-- <li class="onhover-dropdown">
              <app-bookmark></app-bookmark>
            </li> -->
            <!-- <li>
              <div class="mode" (click)="layoutToggle()">
                <svg>
                  <use href="assets/svg/icon-sprite.svg#moon"></use>
                </svg>
              </div>
            </li> -->
            <!-- <li class="cart-nav onhover-dropdown">
              <app-cart></app-cart>
            </li>
            <li class="onhover-dropdown">
              <app-notification></app-notification>
            </li> -->
            <li class="language-nav" (click)="languageToggle()">
              <app-languages></app-languages>
            </li>
            <li class="onhover-dropdown" >
              <app-network [wallet]="wallet"></app-network>
            </li>
            <li class="profile-nav onhover-dropdown p-0" *ngIf="wallet !=null">
              <app-my-account [wallet]="wallet"></app-my-account>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->
