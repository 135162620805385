<swiper [direction]="'vertical'" [loop]="true" [autoHeight]="true" [allowTouchMove]="false" [autoplay]="{ delay: 1000, disableOnInteraction: false }" [pagination]="false" [navigation]="false">
  <!-- <ng-template data-swiper-autoplay="2500" swiperSlide>
    <div class="d-flex h-100">
      <img src="assets/images/giftools.gif" alt="gif" />
      <h6 class="mb-0 f-w-400">
        <a href="https://xgame.live/blogs">
          <span class="font-primary f-light">{{appSettings.translate("Don't Miss Out!")}} </span>
        </a>
        <span class="f-light">{{appSettings.translate("Out new update has been release.")}}</span>
      </h6>
      <i class="icon-arrow-top-right f-light"></i>
    </div>
  </ng-template> -->
  <ng-template data-swiper-autoplay="50000000" swiperSlide>
    <div class="d-flex h-100 section-align-center">
      <h6 class="mb-0 f-w-400 m-r-10 f-light" style="width: auto;">{{appSettings.translate("Announcement")}}: </h6>
      <!-- <img src="assets/images/giftools.gif" alt="gif" /> -->
      <h6 class="mb-0 f-w-400" style="width: auto;">
        <span class="f-light">{{appSettings.translate("EGEM Pre-Sale!")}}</span>
      </h6>
      <h6 class="mb-0 f-w-400 ms-1" style="width: auto;">
        <span class="f-light">{{appSettings.translate("Buy Now!")}}</span>
      </h6>
      <!-- <a class="ms-1 f-light" href="https://dashboard.xgame.live/" target="_blank">{{appSettings.translate("Buy Now!")}}</a> -->
    </div>
  </ng-template>
</swiper>