<div class="card portfolio-card balance-box">
  <div class="card-body">
    <div class="balance-profile">
      <i class="icofont icofont-wallet portfolio-icon"></i>
      <div>
        <span class="f-light d-block my-portfolio" style="margin-top: 8px;">{{appSettings.translate("My Portfolio" | translate)}}</span>
        <div class="portfolio-balance d-flex align-items-center f-light">
          {{ totalBalance | number:'1.2-2' }}
          <div ngbDropdown class="d-inline-block dropdown-currency">
            <button class="btn dropdown-toggle btn-light" type="button"
              data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" ngbDropdownToggle
              style="background-color: transparent !important;">
              <div class="selectedCurrency">{{ portfolioModel.currency }}</div>
            </button>
            <div class="dropdown-menu dropdown-size" ngbDropdownMenu style="z-index: 11 !important;">
              <a class="dropdown-item" ngbDropdownItem href="javascript:void(0)"
                *ngFor="let curr of currency" (click)="handleSelectCurrency(curr)"
                style="opacity: unset; display: flex;">
                <img class="m-r-20" [src]="curr.flag_icon" style="width: 40px; height: auto;">
                <span>{{ curr.name }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <app-loader *ngIf="isLoading" style="opacity: 30%;"></app-loader> -->
